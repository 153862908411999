
.ellipsis {
    max-height: 3.2em;
    line-height: 1.6;
}

::v-deep .blurred {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    user-select: none;
}
