
// hospital-image-banner swiper
.hospital-image-banner .swiper-container {
    overflow: visible;
}
.hospital-image-banner .swiper-slide {
    width: calc((100% / 4) - 8px);
    margin-right: 8px;
}
.hospital-image-banner .swiper-slide .v-image {
    cursor: pointer;
}
.hospital-image-banner .swiper-prev,
.hospital-image-banner .swiper-next {
    width: 32px;
    height: 32px;
}
.hospital-image-banner .swiper-prev {
    left: 10px;
}
.hospital-image-banner .swiper-next {
    right: 10px;
}
.hospital-image-banner .swiper-button-disabled {
    display: none;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

// hospital-image-banner
.hospital-image-banner {
    position: relative;
    z-index: 0;
}

::v-deep .blurred {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    user-select: none;
}
